import React from 'react';

function About(props){
    return(
        <div className="about">
            <h2>About Me</h2>
            <p id="about-text"> 
                <li>• CodeClan graduate.</li>
                <li>• Software Developer Engineer in Test at <a href="https://www.mygov.scot/organisations/disclosure-scotland/" target="_blank" rel="noopener noreferrer">Disclosure Scotland</a>.</li>
                <li>• Background in team-building, staff management and education.</li>
                <li>• Fan of movies and board games.</li>  
            </p>
        </div>
    )
}

export default About;